import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { makeStyles } from "@mui/styles";
import { AppBar, CssBaseline, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";

// third-party
import clsx from "clsx";

// project imports
// import Breadcrumbs from './../../ui-component/extended/Breadcrumbs';
import Header from "./Header";
import Sidebar from "./Sidebar";
// import BackToTop from "./../BackToTop/index";
import navigation from "./../../menu-items";
import { drawerWidth } from "../../store/constant";
import { SET_MENU } from "./../../store/actions";

// assets
// import { IconChevronRight } from "@tabler/icons";
// import CircularProgress from '@material-ui/core/CircularProgress';

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.primary.light,
  },
  appBar: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor:"#000"
  },
  appBarWidth: {
    transition: theme.transitions.create("width"),
    // backgroundColor: theme.palette.background.paper,
    backgroundColor:"#000",
    boxShadow: "0px 3px 6px #00000029",
  },
  content: {
    ...theme.typography.mainContent,
    // borderBottomLeftRadius: 0,
    // borderBottomRightRadius: 0,
    // marginLeft: -(drawerWidth - 20),
    // marginRight: '20px',
    // backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 70),
      width: `calc(100% - ${drawerWidth}px)`,
      // marginRight: '20px',
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginTop: "80px",
      marginRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
      marginTop: "65px",
      marginRight: "20px",
    },
  },
  contentShiftClose: {
    // marginLeft:10,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.provenioai.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  React.useEffect(() => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        // color="inherit"
        elevation={5}
        className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
        // sx={{ paddingLeft: 0 }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
        handleLeftDrawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <main
        className={clsx([
          classes.content,
          {
            [classes.contentShift]: leftDrawerOpened,
            [classes.contentShiftClose]: !leftDrawerOpened,
          },
        ])}
      >
        {/* <Main open={leftDrawerOpened}> */}
        {/* breadcrumb */}
        {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}

        <Outlet />
        {/* <BackToTop /> */}
        {/* </Main> */}
      </main>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;

// import React from 'react'

// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Outlet } from 'react-router-dom';

// // material-ui
// import { styled, useTheme } from '@mui/material/styles';
// import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// // project imports
// // import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// import Header from './Header/index';
// import Sidebar from './Sidebar';
// import Customization from '../Customization';
// import navigation from '../../menu-items';
// import { drawerWidth } from '../../store/constant';
// import { SET_MENU } from '../../store/actions';

// // assets
// import { IconChevronRight } from '@tabler/icons';

// // styles
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
//     ...theme.typography.mainContent,
//     ...(!open && {
//         borderBottomLeftRadius: 0,
//         borderBottomRightRadius: 0,
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen
//         }),
//         [theme.breakpoints.up('md')]: {
//             marginLeft: -(drawerWidth - 20),
//             width: `calc(100% - ${drawerWidth}px)`
//         },
//         [theme.breakpoints.down('md')]: {
//             marginLeft: '20px',
//             width: `calc(100% - ${drawerWidth}px)`,
//             padding: '16px'
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: '10px',
//             width: `calc(100% - ${drawerWidth}px)`,
//             padding: '16px',
//             marginRight: '10px'
//         }
//     }),
//     ...(open && {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen
//         }),
//         marginLeft: 0,
//         borderBottomLeftRadius: 0,
//         borderBottomRightRadius: 0,
//         width: `calc(100% - ${drawerWidth}px)`,
//         [theme.breakpoints.down('md')]: {
//             marginLeft: '20px'
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: '10px'
//         }
//     })
// }));

// // ==============================|| MAIN LAYOUT ||============================== //

// const MainLayout = () => {
//     const theme = useTheme();
//     const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

//     // Handle left drawer
//     const leftDrawerOpened = useSelector((state) => state.customization.opened);
//     const dispatch = useDispatch();
//     const handleLeftDrawerToggle = () => {
//         dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
//     };

//     useEffect(() => {
//         dispatch({ type: SET_MENU, opened: !matchDownMd });
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [matchDownMd]);

//     return (
//         <Box sx={{ display: 'flex' }}>
//             <CssBaseline />
//             {/* header */}
//             <AppBar
//                 enableColorOnDark
//                 position="fixed"
//                 color="inherit"
//                 elevation={0}
//                 sx={{
//                     bgcolor: theme.palette.background.default,
//                     transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
//                 }}
//             >
//                 <Toolbar>
//                     <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
//                 </Toolbar>
//             </AppBar>

//             {/* drawer */}
//             <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

//             {/* main content */}
//             <Main theme={theme} open={leftDrawerOpened}>
//                 {/* breadcrumb */}
//                 {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
//                 <Outlet />
//             </Main>
//             <Customization />
//         </Box>
//     );
// };

// export default MainLayout;
