// assets
// import { IconBrain } from "@tabler/icons";

// constant
// const icons = {
//     IconBrain,
// };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const genAI = {
  id: "genAI",
  title: "Gen AI",
  caption: "genAI",
  type: "group",
  children: [
    {
      id: "GenerativeAI",
      title: "Generative AI",
      type: "collapse",
      icon: "tabler:brain",
      children: [
        {
          id: "auto-analyst",
          title: "DomainGPT",
          titleType: "collapse",
          type: "item",
          url: "http://chatui.z13.web.core.windows.net",
          target: true,
          icon: "radix-icons:dot-filled",
        },
        {
          id: "demo-analyst",
          title: "AI Writemate",
          titleType: "collapse",
          type: "item",
          url: "https://web.aiwritemate.com",
          target: true,
          icon: "radix-icons:dot-filled",
        },
        {
          id: "actionable-intelligence",
          title: "Actionable Intelligence",
          titleType: "collapse",
          type: "item",
          url: "/actionable-intelligence",
          target: false,
          icon: "radix-icons:dot-filled",
        }
      ]
    }
  ]
};

export default genAI;
