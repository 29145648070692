import { lazy } from "react";

// project imports
import Loadable from "../component/Loadable/Index";
import MinimalLayout from "../layout/MinimalLayout/index";
const Login = Loadable(lazy(() => import("../container/Login/Index")));
const Overview = Loadable(lazy(() => import("../container/OverView/Index")))
const FoodBeverage = Loadable(lazy(() => import('../container/OverView/FoodBeverage')));
const Procurement = Loadable(lazy(() => import('../container/OverView/Procurement')))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/overview",
      element: <Overview />,
    },
    { 
      path: "/food-beverage",
      element: <FoodBeverage />
    },
    { 
      path: "/procurement",
      element: <Procurement />
    }
  ],
};

export default AuthenticationRoutes;
