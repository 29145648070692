// assets
// import { IconLayoutGrid } from '@tabler/icons';

// constant
// const icons = { IconLayoutGrid };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const imageIntelligence = {
    id: 'imageIntelligence',
    title: 'Image Intelligence Suite',
    type: 'group',
    children: [
        {
            id: 'imageIntelligence',
            title: 'Image Intelligence Suite',
            titleType:"normal",
            type: 'item',
            url: '/image-intelligence',           
            icon: "tabler:photo-ai",
            breadcrumbs: false
        }
    ]
};

export default imageIntelligence;
