import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import Iconify from '../../../../../component/iconify/Index';


// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //
const useStyles = makeStyles((theme) => (
    {
  
      titleHide: {
        display: "none",
      },
       normalProperty :{
        ...theme.typography.menuSelectNormal,  
      },    
       collopsProperty :{
        ...theme.typography.menuSelectCollapse,        
      },

    }));
const NavCollapse = ({ menu, level }) => {
        const classes = useStyles();   
    const theme = useTheme();
    let data = menu.titleType
    const customization = useSelector((state) => state.provenioai);
    const leftDrawerOpened = useSelector((state) => state.provenioai.opened);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };
    // console.log(customization?.menuId, menu.id)

    useEffect(() => {
        if(menu.id === customization?.menuId) {
            setOpen(!open);
            setSelected(!selected ? menu.id : null);
        }
    },[])

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icons = menu.icon;
    const menuIcon = menu.icon ? (
        <>
        {
            leftDrawerOpened? 
            <Icons strokeWidth={1.3} size={menu.id === "fraitey" ? "1.3rem" : "1.5rem"} style={{ marginTop: 'auto', marginBottom: 'auto' }} />:
            <Icons strokeWidth={1.3} size={menu.id === "fraitey" ? "1.6rem" : "1.8rem"} style={{ marginTop: 'auto', marginBottom: 'auto' }} />

        }
        </>
       
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 12 : 12,
                height: selected === menu.id ? 12 : 12
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    // backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 4}px`,
                    pb: "6px",
                    pt: "6px",
                    // mb: 0,
                    display: "flex",
                    // alignItems: "center"

                }}
                className={clsx({
                    [classes.collopsProperty]: data === "collapse",
                    [classes.normalProperty]: data === "normal",
                  })}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                {/* <img src={menu.icon} width="20px"/> */}
                {/* <SvgIcon component={menu.icon} inheritViewBox /> */}
                {
                    menu.id === "fraitey" ? (
                        <Stack spacing={0}>
                            <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36,display:"flex",justifyContent:"center" }}>
                               {/* {menuIcon}  */}
                              
                                <Iconify width={20} icon={"ic:round-menu"} />
                                </ListItemIcon>
                            <Icon sx={{ mb: "-9px", mt: "-9px",ml:"5px" }}>linear_scale</Icon>

                        </Stack>



                    ) : (
                        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36,display:"flex",justifyContent:"center" }}>
                            {/* {menuIcon} */}
                            <Iconify width={18} icon={menu.icon}/>
                            </ListItemIcon>
                    )
                }
                <ListItemText
                sx={{whiteSpace:"break-spaces"}}
                    primary={
                        <Typography
                            variant={selected === menu.id ? 'h5' : 'body1'} sx={{ my: 'auto', color: theme.palette.background.paper, display: !leftDrawerOpened && "none" }}>
                            {menu.title}
                        </Typography>
                    }
                />
                {open ? (
                    // <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    <Iconify width={16} icon={"ion:chevron-up"} />
                ) : (
                    // <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    <Iconify width={16} icon={"ion:chevron-down"} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        pt: 0,
                        pb: 0,
                        // '&:after': {
                        //     content: "''",
                        //     position: 'absolute',
                        //     left: '32px',
                        //     top: 0,
                        //     height: '100%',
                        //     width: '1px',
                        //     opacity: 1,
                        //     background: theme.palette.background.paper
                        // }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
