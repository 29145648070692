import React, { useEffect, useMemo, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
// store
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

// routing
import Routes from "./routes/index";

// defaultTheme
import themes from "./themes/index";

// project imports
import NavigationScroll from "./component/NavigationScroll/Index";
import Snackbar from "./component/Snackbar/Index";
import SessionExpire from "./component/Modal/SessionExpire";
import { SNACKBAR_STATUS } from "./store/actions";
import { LOGOUT, THREAD_ID } from "./store/actions";

// ==============================|| APP ||============================== //

const App = () => {
  const navigate = useNavigate();
  const currentUrl = window.location.pathname;
  let tokenExpireTime = Cookies.get("tokenExpireTime");



  const [sessionOpen, setSessionOpen] = useState(false);

  const customization = useSelector((state) => state.provenioai);
  const { snackbarStatus } = customization;
  const dispatch = useDispatch();


  const handleCloseSnackbar = () => {
    dispatch({
      type: SNACKBAR_STATUS,
      snackbarStatus: {
        snackbarOpen: false,
        snackbarTitle: "",
        snackbarseverity: "",
      },
    });
  };
  const handleSessionExpire = async () => {
    dispatch({ type: LOGOUT });
    Cookies.remove("provenioToken");
    Cookies.remove("tokenExpireTime");
    setSessionOpen(false);
    navigate("/");
  };

  useEffect(() => {
    dispatch({
      type: THREAD_ID,
      threadId: "",
    });
  }, [currentUrl]);
  
  useEffect(() => {
    if (Date.now() >= tokenExpireTime * 1000) {
      console.log("eter");
      setSessionOpen(true);
    }
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
          <Snackbar
            open={snackbarStatus?.snackbarOpen}
            title={snackbarStatus?.snackbarTitle}
            severity={snackbarStatus?.snackbarseverity}
            close={handleCloseSnackbar}
          />
          <SessionExpire
            open={sessionOpen}
            title="Session has been Expired!"
            description="Please re-login to continue"
            confirm={handleSessionExpire}
          />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
