import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Box } from '@mui/material';

// project imports
import config from '../../config';
import Logo from '../../assets/images/logo/iris-icon.png';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (
        // <ButtonBase disableRipple component={Link} to={"/dashboard"} sx={{padding: "13px 10px 20px 5px"}}>
            <Box sx={{padding: "13px 10px 20px 5px"}}>
<img src={Logo} width="40px"/>
</Box>
        // </ButtonBase>
    );
};

export default LogoSection;
