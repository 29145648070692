/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme) {
  return {
    fontFamily: theme?.customization?.fontFamily,
    h6: {
      fontWeight: 500,
      color: theme.heading,
      fontSize: "0.75rem",
    },
    h5: {
      fontSize: "0.82rem",
      color: theme.heading,
      fontWeight: 500,
    },
    h4: {
      fontSize: "1rem",
      color: theme.heading,
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.25rem",
      color: theme.heading,
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.5rem",
      color: theme.heading,
      fontWeight: 700,
    },
    h1: {
      fontSize: "2.125rem",
      color: theme.heading,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "0.8rem",
      fontWeight: 500,
      color: theme.textDark,
    },
    subtitle2: {
      fontSize: "0.75rem",
      fontWeight: 400,
      color: theme.darkTextSecondary,
    },
    caption: {
      fontSize: "0.75rem",
      color: theme.darkTextSecondary,
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.8rem",
      fontWeight: 400,
      lineHeight: "1.334em",
    },
    body2: {
      letterSpacing: "0em",
      fontWeight: 400,
      lineHeight: "1.5em",
      color: theme.darkTextPrimary,
      fontSize: "0.8rem",
    },
    button: {
      textTransform: "capitalize",
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        top: 23,
        left: 0,
        color: theme.grey500,
        '&[data-shrink="false"]': {
          top: 5,
        },
      },
      "& > div > input": {
        padding: "30.5px 14px 11.5px !important",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    mainContent: {
      backgroundColor: theme.background,
      width: "100%",
      minHeight: "calc(100vh - 88px)",
      flexGrow: 1,
      padding: "20px",
      marginTop: "88px",
      marginRight: "20px",
      borderRadius: `${theme?.customization?.borderRadius}px`,
    },
    headerButton:{
      borderRadius:"26px",
      backgroundColor:"#FFFFFF",
      color:"#C20158",
      fontWeight:600,
      borderColor:"#00000029",
      "&:hover": {
        backgroundColor:"#FFFFFF",
        borderColor:"#00000029",
      }
    },
    menuSelectNormal: {
      // marginLeft:"10px",
      "&:hover": {
        // backgroundColor: theme.menuSelected,
        // background: "#55CEFD 0% 0% no-repeat padding-box",

        borderRadius: 8,
      },
      //   "& .MuiListItemIcon-root": {
      //       color:theme.paper,
      //   },
      "&.Mui-selected": {
        color: theme.paper,
        // backgroundColor:"#0660FE",
        // background: "#55CEFD 0% 0% no-repeat padding-box",
        borderRadius: 8,
       

        "&:hover": {
          // background: "#55CEFD 0% 0% no-repeat padding-box",
          borderRadius: 8,
        },
        "& .MuiListItemIcon-root": {
          color: theme.paper,
        },
      },
    },
    menuSelectCollapse: {
      width: "85%",
      marginLeft: "1rem",
      paddingLeft: "2px",
      "&:hover": {
        color: theme.paper,
        // background: "#55CEFD 0% 0% no-repeat padding-box",
        borderRadius: 8,
        // marginLeft:"1.4rem",
      },
      "& .MuiListItemIcon-root": {
        color: theme.paper,
      },
      "&.Mui-selected": {
        color: theme.paper,
        // background: "#55CEFD 0% 0% no-repeat padding-box",
        borderRadius: 8,
        // marginLeft:"1.4rem",
        // width: "95%",
        // paddingBottom: "5px",
        // paddingTop: "5px",

        "&:hover": {
          // background: "#55CEFD 0% 0% no-repeat padding-box",
          borderRadius: 8,
        },
        "& .MuiListItemIcon-root": {
          color: theme.paper,
        },
      },
    },
    menuCaption: {
      fontSize: "0.8rem",
      fontWeight: 500,
      color: theme.heading,
      padding: "6px",
      textTransform: "capitalize",
      marginTop: "10px",
      
    },
    subMenuCaption: {
      fontSize: "0.6875rem",
      fontWeight: 500,
      color: theme.darkTextSecondary,
      textTransform: "capitalize",
    },
    commonAvatar: {
      cursor: "pointer",
      borderRadius: "8px",
    },
    smallAvatar: {
      width: "22px",
      height: "22px",
      fontSize: "1rem",
    },
    mediumAvatar: {
      width: "42px",
      height: "42px",
      fontSize: "1.2rem",
    },
    largeAvatar: {
      width: "44px",
      height: "44px",
      fontSize: "1.5rem",
    },
  };
}
