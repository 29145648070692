import { combineReducers } from 'redux';

// reducer import
import provenioaiReducer from './provenioaiReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    provenioai: provenioaiReducer
});

export default reducer;
