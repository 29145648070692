// assets
// import { IconPackage,IconBrain, IconFileDescription } from "@tabler/icons";

// constant
// const icons = {
//   IconPackage, IconBrain, IconFileDescription
// };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const fraitey = {
  id: "fraitey",
  title: "Fraitey",
  caption: "Fraitey",
  type: "group",
  children: [
    {
      id: "documentIntelligenceSuite",
      title: "Document Intelligence Suite",
      type: "collapse",
      icon: "tabler:file-text-ai",
      children: [
        // {
        //   id: "supplyChain",
        //   title: "Document Intelligence Suite",
        //   type: "collapse",
        //   children:[
            {
              id: "supplyChain",
              title: "Invoice Intelligence - Freight",
              type: "collapse",
              icon: "radix-icons:dot-filled",
              children: [
                {
                  id: "inbound",
                  title: "Inbound",
                  titleType: "collapse",
                  type: "item",
                  url: "/inbound",
                  target: false,
                  icon: "ph:dot-outline-fill",
                },
                // {
                //   id: "outbound",
                //   title: "Outbound",
                //   titleType: "collapse",
                //   type: "item",
                //   url: "/outbound",
                //   target: false,
                //   icon: "ph:dot-outline-fill",
                // },
                {
                  id: "pod",
                  title: "Proof of Delivery",
                  titleType: "collapse",
                  type: "item",
                  url: "/pod",
                  target: false,
                  icon: "ph:dot-outline-fill",
                },
              ],
            },
            {
              id: "InvoiceIntelligenceAP",
              title: "Invoice Intelligence - AP",
              type: "collapse",
              icon: "radix-icons:dot-filled",
              children: [
                // {
                //   id: "cost-allocation",
                //   title: "Cost Allocation",
                //   titleType: "collapse",
                //   type: "item",
                //   url: "/cost-alloction",
                //   target: false,
                //   icon: "ph:dot-outline-fill",
                // },
                {
                  id: "PO-match",
                  title: "3 way PO Match",
                  titleType: "collapse",
                  type: "item",
                  url: "/three-way-po-match",
                  target: false,
                  icon: "ph:dot-outline-fill",
                }
              ],
            },
            {
              id: "Document-Intelligence-Contract",
              title: "Document Intelligence - Contract",
              titleType: "collapse",
              type: "item",
              url: "https://contractdemo.irisapac.com",
              target: true,
              icon: "radix-icons:dot-filled",
            },
            {
              id: "classification",
              title: "Document Classification",
              titleType: "collapse",
              type: "item",
              url: "/document-classification",
              target: false,
              icon: "radix-icons:dot-filled",
            },
            {
              id: "summarisation",
              title: "Document Summarisation",
              titleType: "collapse",
              type: "item",
              url: "/document-summarisation",
              target: false,
              icon: "radix-icons:dot-filled",
            },
            {
              id: "interpretation",
              title: "Document Interpretation",
              titleType: "collapse",
              type: "item",
              url: "/document-interpretation",
              target: false,
              icon: "radix-icons:dot-filled",
            },
        //   ],
          
        // }
      ]
      

     
    },
  ],
};

export default fraitey;
