// assets
// import { IconPackage, Icon123 } from "@tabler/icons";

// constant
// const icons = {
//   IconPackage, Icon123
// };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const classification = {
  id: "classification",
  title: "Classification",
  caption: "classification",
  type: "group",
  children: [
    {
      id: "HyperAutomation",
      title: "Hyper Automation",
      type: "collapse",
      icon: "tabler:automation",

      children: [
        {
          id: "power-automate",
          title: "Power automate",
          titleType: "collapse",
          type: "item",
          url: "/",
          target: true,
          icon: "radix-icons:dot-filled",
        },
        // {
        //   id: "gpt-model",
        //   title: "GPT Model",
        //   titleType: "collapse",
        //   type: "item",
        //   url: "https://gpt-model.provenio.ai/",
        //   target: true,
        // },
      ],
    },
  ],
};

export default classification;
