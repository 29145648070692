import { lazy } from "react";

// project imports
import Loadable from "../component/Loadable/Index";
import MinimalLayout from "../layout/MinimalLayout/index";
const Login = Loadable(lazy(() => import("../container/Login/Index")));
const Dashboard = Loadable(lazy(() => import("../container/OverView/Index")))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const DashboardRoutes = {
  
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
  ],
};

export default DashboardRoutes;
