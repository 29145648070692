export const SET_MENU = "/SET_MENU";
export const MENU_TOGGLE = "/MENU_TOGGLE";
export const MENU_OPEN = "/MENU_OPEN";
export const SET_FONT_FAMILY = "/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "/SET_BORDER_RADIUS";
export const NAVIGATE = "/NAVIGATE";
export const TOKEN = "TOKEN";
export const SNACKBAR_STATUS = "/SNACKBAR_STATUS";
export const LOGOUT = "/LOGOUT";
export const USER_DATA = "/USER_DATA";
export const API_TRIGGER = "/API_TRIGGER";
export const INVOICE_STATUS = "INVOICE_STATUS";
export const ALERT_MESSAGE = "ALERT_MESSAGE";
export const COSTALLOCATION_VALUES = "COSTALLOCATION_VALUES";
export const TICKET_STATUS = "TICKET_STATUS";
export const AUTO_ANALYST_DATA = "/AUTO_ANALYST_DATA";
export const DEMO_ANALYST_DATA = "/DEMO_ANALYST_DATA";
export const THREAD_ID = "/THREAD_ID";
export const AI_ASSISTANT = "/AI_ASSISTANT";
export const MENU_ID = "/MENU_ID"