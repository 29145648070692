import { Link } from "react-router-dom";

// material-ui
import { ButtonBase, Stack } from "@mui/material";

// project imports
// import config from "../../config";
import Logo from "../../assets/images/logo/iris_logo.png";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  // <ButtonBase disableRipple component={Link} to={"/dashboard"}>
    <Stack direction="row" alignItems="center" justifyContent="center" mb={2}>
    <img src={Logo} width="140px" />
    </Stack>
  // </ButtonBase>
);

export default LogoSection;
