// assets
// import { IconLayoutGrid } from '@tabler/icons';

// constant
// const icons = { IconLayoutGrid };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            titleType:"normal",
            type: 'item',
            url: '/dashboard',           
            icon: "lucide:layout-dashboard",
            breadcrumbs: false
        }
    ]
};

export default dashboard;
