// assets
// import { IconLayoutGrid } from '@tabler/icons';

// constant
// const icons = { IconLayoutGrid };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const contractManagement = {
    id: 'contractManagement',
    title: 'Contract Management',
    type: 'group',
    children: [
        {
            id: 'contractManagement',
            title: 'Contract Management',
            titleType:"normal",
            type: 'item',
            url: '/contract-management',           
            icon: "mdi:contract-outline",
            breadcrumbs: false
        }
    ]
};

export default contractManagement;
