import { lazy } from "react";

// project imports
// import Loadable from 'ui-component/Loadable';
import Loadable from "../component/Loadable/Index";
import MainLayouts from "../layout/MainLayout/index";

// login option 3 routing
const HomePage = Loadable(
  lazy(() => import("../container/HomePage/Index"))
);

const Inbound = Loadable(
  lazy(() => import("../container/SupplyChain/Inbound/Index"))
);
const Outbound = Loadable(
  lazy(() => import("../container/SupplyChain/Outbound/Index"))
);

const InboundInvoiceView = Loadable(
  lazy(() => import("../container/SupplyChain/Inbound/InvoiceView"))
);
const OutboundAnalytics = Loadable(
  lazy(() => import("../container/SupplyChain/Outbound/ViewAnalytics/Index"))
);

const ViewAnalytics = Loadable(
  lazy(() => import("../component/ViewAnalytics/ViewAnalytics"))
);

const OutboundInvoiceView = Loadable(
  lazy(() => import("../container/SupplyChain/Outbound/InvoiceView"))
);

const AutoAnalyst = Loadable(lazy(() => import("../container/GenAI/AutoAnalyst/Index")))

const Summarisation = Loadable(lazy(() => import('../container/GenAI/AutoAnalyst/Summarization')))

const Interpretation = Loadable(lazy(() => import('../container/GenAI/AutoAnalyst/Interpretation')))

const Intelligence = Loadable(lazy(() => import('../container/GenAI/AutoAnalyst/Intelligence')))

const ActionableIntelligence = Loadable(lazy(() => import('../container/GenAI/AutoAnalyst/ActionableIntelligence')))

const POD = Loadable( lazy(() => import("../container/Pod/Index")))

const ViewPod = Loadable(lazy(() => import("../container/Pod/ViewPodDetails")))

const CostAllocationVeiwAnalytics = Loadable(
  lazy(() => import("../container/CostAllocation/ViewAnalytics/Index"))
);

const ThreeWayPoMatchInvoiceView = Loadable(
  lazy(() => import("../container/ThreeWayPoMatch/InvoiceView"))
);
const TelstraInvoiceView = Loadable(
  lazy(() => import("../container/CostAllocation/Telstra/InvoiceView"))
);
const Gs1InvoiceView = Loadable(
  lazy(() => import("../container/CostAllocation/Gs1/InvoiceView"))
);
const AbmlInvoiceView = Loadable(
  lazy(() => import("../container/CostAllocation/Abml/InvoiceView"))
);

const ThreeWayPoMatch = Loadable(
  lazy(() => import("../container/ThreeWayPoMatch/Invoices"))
);
const CostAllocation = Loadable(
  lazy(() => import("../container/CostAllocation/Index"))
);

const DocumentIntelligenceContact = Loadable(
  lazy(() => import('../container/DocumentIntelligenceContact/DocumentIntelligenceContact'))
)

const Dashboard = Loadable(
  lazy(() => import("../container/Dashboard/Index"))
)

const ImageIntelligence = Loadable(
  lazy(() => import("../container/ImageIntelligence/Index"))
)

const ContractManagement = Loadable(
  lazy(() => import('../container/ContractManagement/Index'))
)

const NewProject = Loadable(
  lazy(() => import('../container/ContractManagement/NewProject'))
)

const ContractManagementView = Loadable(
  lazy(() => import('../container/ContractManagement/ContractManagement'))
)

const Templetes = Loadable(
  lazy(() => import('../container/ContractManagement/Templetes'))
)


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const MainLayout = {
  path: "/",
  element: <MainLayouts />,
  children: [
    {
      path: "/home-page",
      element: <HomePage />,
    },    
    {
      path: "/view-analytics",
      element: <ViewAnalytics />,
    },
    {
      path: "/inbound",
      element: <Inbound />,
    },
    {
      path: "/inbound-view",
      element: <InboundInvoiceView />,
    },
    {
      path: "/outbound",
      element: <Outbound />,
    },
    {
      path: "/outbound-view",
      element: <OutboundInvoiceView />,
    },
    {
      path: "/document-classification",
      element: <AutoAnalyst />,
    },
    {
      path: "/document-summarisation",
      element: <Summarisation />,
    },
    {
      path: "/document-interpretation",
      element: <Interpretation />,
    },
    {
      path: "/invoice-intelligence",
      element: <Intelligence />,
    },
    {
      path: "/actionable-intelligence",
      element: <ActionableIntelligence />,
    },
    {
      path: "/pod",
      element: <POD />,
    },

    {
      path: "/pod-details",
      element: <ViewPod />,
    },
    {
      path: "/viewanalytics",
      element: <OutboundAnalytics />,
    },
    {
      path: "/cost-allocation-viewanalytics",
      element: <CostAllocationVeiwAnalytics />,
    },
    {
      path: "/cost-alloction",
      element: <CostAllocation />,
    },
    {
      path: "/abml-view",
      element: <AbmlInvoiceView />,
    },
    {
      path: "/gs1-view",
      element: <Gs1InvoiceView />,
    },
    {
      path: "/telstra-view",
      element: <TelstraInvoiceView />,
    },
    {
      path: "/three-way-po-match",
      element: <ThreeWayPoMatch />,
    },
    {
      path: "/three-way-po-match-view",
      element: <ThreeWayPoMatchInvoiceView />,
    },
    {
      path: "/document-intelligence-contract",
      element: <DocumentIntelligenceContact />,
    },
    {
      path:"/dashboard",
      element:<Dashboard/>
    },
    {
      path:"/image-intelligence",
      element:<ImageIntelligence/>
    },
    {
      path:"/contract-management",
      element:<ContractManagement/>
    },
    {
      path:"/new-project",
      element:<NewProject/>
    },
    {
      path:"/contract-management-view",
      element:<ContractManagementView/>
    },
    {
      path:"/templates",
      element:<Templetes/>
    }
  ],
};

export default MainLayout;
