import { BorderBottom } from "@mui/icons-material";

export default function componentStyleOverrides(theme) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: "4px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.paper,
          paddingTop: "10px",
          paddingBottom: "10px",
          // '&.Mui-selected': {
          //     color:theme.paper,
          //     backgroundColor: theme.menuSelected,
          //     '&:hover': {
          //         backgroundColor: theme.menuSelected
          //     },
          //     '& .MuiListItemIcon-root': {
          //         color: theme.paper
          //     }
          // },
          // '&:hover': {
          //     backgroundColor: theme.menuSelected,
          //     color: theme.paper,
          //     '& .MuiListItemIcon-root': {
          //         color:theme.paper
          //     }
          // }
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.paper,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.paper,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          "&::placeholder": {
            color: theme.darkTextSecondary,
            fontSize: "0.8rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors?.grey400,
          },
          "&:hover $notchedOutline": {
            borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          padding: "15.5px 14px",
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#E54E15",
          height: "3px",
          borderRadius: "6px",
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: "0px !important",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "13px",
          fontWeight: "600",

          "& .Mui-selected": {
            color: "#E54E15",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: "10px",
          border:"1px solid grey",
          "&.Mui-selected": {
            backgroundColor: "#e54e1540",
            border:"1px solid #E54E15",
            color: "#000",
            padding: "4px",
            "&:hover": {
              backgroundColor: "#e54e154a",
              // color: "#ffffff",
            },
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          fontSize: "16px",
          fontWeight:900,
          color:"#000000",
          textTransform: "capitalize",
          letterSpacing: "0.4px",
        },
        data: {
          fontSize: "16px",
          fontWeight: 900,
          color:"#000000",
          textTransform: "capitalize",
          letterSpacing: "0.4px",
          //  width:"100px"
        },
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "white",
      },
    },
    MUIDataTableBodyCell:{
      styleOverrides: {
        root:{
          "& > p": {
           fontWeight:600,
          },
        },
        stackedCommon:{
          fontSize: "14px",
          fontWeight:600,
          color:"#000000",
          letterSpacing: "0.96px",
      
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "13px",
          fontWeight:600,
          color:"#000000",
          letterSpacing: "0.96px",
       
        }
      }
    }
  };
}
