import React, {  useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

// npm 
import { useDispatch } from "react-redux";


function AlertModal(props) {

    const handleSessionExpire=async()=>{        
      props.confirm()
    }

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={props.open}
        aria-labelledby="draggable-dialog-title"
        maxWidth={"xs"}
      >
        {props.title && (
          <DialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            <Typography variant="h3"> {props.title}</Typography>
          </DialogTitle>
        )}
        {props.description && (
          <DialogContent>
            <DialogContentText>{props.description}</DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          
          <LoadingButton
            sx={{ fontSize: "1rem", width: "100px"}}
            size="small"
            type="submit"
            variant="contained"
            onClick={handleSessionExpire}
          >
            {/* {props.submitBtnText ? props.submitBtnText : "Yes! Edit"} */}
            Re-login
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertModal;
