import PropTypes from "prop-types";
import React from "react";

// material-ui
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";
import clsx from "clsx";

// project imports
import MenuList from "./MenuList";
import LogoSection from "../../../component/Logo/DrawerLogo";
import MiniLogoSection from "../../../component/Logo/MiniDrawerLogo";

import { drawerWidth } from "./../../../store/constant";

// style constant
const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: theme.palette.primary.main,
    position: "fixed",
    top: "15px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    "&:hover": {
      color: theme.palette.primary.main,
      position: "fixed",
      top: "15px",
      backgroundColor: theme.palette.background.paper,
    },
    // width: 70,
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // overflowX: "hidden",
  },
  drawer: {
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRight: "none",
    [theme.breakpoints.up("md")]: {
      top: "76px",
    },
  },
  ScrollHeight: {
    height: "calc(100vh - 40px)",
    // paddingLeft: '16px',
    // paddingRight: '16px',
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },
  boxContainer: {
    display: "flex",
    // padding: '16px',
    marginLeft: "auto",
    marginRight: "auto",
    // background: theme.palette.primary.main,
    alignItems: "center",
    justifyContent:"center",
    marginTop:"1rem"
    // justifyContent: "space-around"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  DesktopDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerClose: {
    width: 70,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: theme.spacing(2) + 1,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(2) + 1,
    // },
  },
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({
  drawerOpen,
  drawerToggle,
  window,
  handleLeftDrawerToggle,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const drawer = (
    <React.Fragment>
      <BrowserView>
        <PerfectScrollbar component="div" className={classes.ScrollHeight}>
          <Box>
            <div className={classes.boxContainer}>
              {drawerOpen ? (
                <>
                  <LogoSection />
                  <IconButton
                    onClick={handleLeftDrawerToggle}
                    className={classes.menuIcon}
                    sx={{
                      left: drawerWidth - 20,
                      // left:drawerOpen?drawerWidth-20:50,
                    }}
                  >
                    <MenuOpenOutlinedIcon stroke={1.5} size="1.2rem" />
                  </IconButton>
                </>
              ) : (
                <>
                  <MiniLogoSection />
                  <IconButton
                    onClick={handleLeftDrawerToggle}
                    className={classes.menuIcon}
                    sx={{
                      left: 55,
                      // left:drawerOpen?drawerWidth-20:50,
                    }}
                  >
                    <MenuOutlinedIcon stroke={1.5} size="0.8rem" />
                  </IconButton>
                </>
              )}
            </div>

            <MenuList />
          </Box>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box>
          <div className={classes.boxContainer}>
            {drawerOpen ? (
              <>
                <LogoSection />
                <IconButton
                  onClick={handleLeftDrawerToggle}
                  className={classes.menuIcon}
                  sx={{
                    left: drawerWidth - 20,
                    // left:drawerOpen?drawerWidth-20:50,
                  }}
                >
                  <MenuOpenOutlinedIcon stroke={1.5} size="1.2rem" />
                </IconButton>
              </>
            ) : (
              <>
                <MiniLogoSection />
                <IconButton
                  onClick={handleLeftDrawerToggle}
                  className={classes.menuIcon}
                  sx={{
                    left: 55,
                    // left:drawerOpen?drawerWidth-20:50,
                  }}
                >
                  <MenuOutlinedIcon stroke={1.5} size="0.8rem" />
                </IconButton>
              </>
            )}
          </div>

          <MenuList />
        </Box>
      </MobileView>
    </React.Fragment>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {matchUpMd ? (
          <Drawer
            container={container}
            variant={"permanent"}
            anchor="left"
            open={drawerOpen}
            onClose={drawerToggle}
            className={clsx(classes.DesktopDrawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              }),
            }}
            sx={{
              // display: { xs: 'block', sm: 'none' },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                // backgroundColor: theme.palette.primary.main,
                background: "transparent linear-gradient(180deg, #813A39 50%, #C20158 100%) 0% 0% no-repeat padding-box",
                padding: drawerOpen && "10px",
                paddingTop: 0,
                // marginTop: "70px",
              },
            }}
            // classes={{
            //     paper: classes.drawerPaper
            // }}
            ModalProps={{ keepMounted: true }}
            color="inherit"
          >
            {drawer}
          </Drawer>
        ) : (
          <Drawer
            container={container}
            variant="temporary"
            open={drawerOpen}
            onClose={drawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              // display: { xs: 'block', sm: 'none' },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                // backgroundColor: theme.palette.primary.main,
                background: "transparent linear-gradient(180deg, #813A39 50%, #C20158 100%) 0% 0% no-repeat padding-box",
                width: drawerWidth,
                padding: drawerOpen && "10px",
                paddingTop: 0,
              },
            }}
          >
            {drawer}
          </Drawer>
        )}
      </nav>
    </>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
  handleLeftDrawerToggle: PropTypes.func,
};

export default Sidebar;
