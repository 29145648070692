import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import themePalette from "../../../../../themes/palette";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import { MENU_OPEN, SET_MENU, INVOICE_STATUS } from "../../../../../store/actions";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Iconify from '../../../../../component/iconify/Index';

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  titleHide: {
    display: "none",
  },
  normalProperty: {
    ...theme.typography.menuSelectNormal,
  },
  collopsProperty: {
    ...theme.typography.menuSelectCollapse,
  },
  listIcon: {
    minWidth: "18px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  listCustomIconSub: {
    width: "12px",
    height: "12px",
  },
  listCustomIconSubActive: {
    width: "12px",
    height: "12px",
  },
  listItem: {
    marginBottom: "5px",
    alignItems: "center",
    color: "#616161",
  },
  listItemNoBack: {
    marginBottom: "5px",
    // backgroundColor: "transparent !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    alignItems: "flex-start",
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
}));

const NavItem = ({ item, level }) => {
  const classes = useStyles();
  const theme = useTheme();
  let data = item.titleType;
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.provenioai);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const leftDrawerOpened = useSelector((state) => state.provenioai.opened);

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <>
      {leftDrawerOpened ? (
        <Icon stroke={1.5} size="1.3rem" />
      ) : (
        <Icon stroke={1.5} size="1.8rem" />
      )}
    </>
  ) : (
    <FiberManualRecordIcon
      sx={{
        width:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };
const redirectUrl=(id)=>{
  if(id==="classification-model")
  {
    window.open("https://classification-model.provenio.ai/", '_blank');
   
  }
  else if(id==="gpt-model")
  {
    window.open("https://gpt-model.provenio.ai/", '_blank');
  }
}
  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, []);

  const handleTitleClick = () => {
    dispatch({ type: INVOICE_STATUS, invoiceStatus: 'ALL' });
  };


  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      className={clsx({
        [classes.collopsProperty]: data === "collapse",
        [classes.normalProperty]: data === "normal",
      })}
      
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: "flex-start",
        // backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 5}px`,
        pt: "6px",
        pb: "6px",
      }}
      selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
      onClick={() =>["classification-model","gpt-model"].includes(item.id)?redirectUrl(item.id):itemHandler(item.id)}
    >
      
      <ListItemIcon
      onClick={handleTitleClick}
        sx={{
          my: "auto",
          minWidth: !item?.icon ? 18 : 36,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* {itemIcon} */}
        <Iconify width={18} icon={item.icon} />
      </ListItemIcon>
      <ListItemText
            sx={{whiteSpace:"break-spaces",}}
        primary={
          <Typography
            className={clsx({
              [classes.titleHide]: !leftDrawerOpened,
            })}
            variant={
              customization.isOpen.findIndex((id) => id === item.id) > -1
                ? "h5"
                : "body2"
            }
            sx={{ color: theme.palette.background.paper }}
            onClick={handleTitleClick}
          >
            {item.title}
          </Typography>
        }
        // secondary={
        //     item.caption && (
        //         <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
        //             {item.caption}
        //         </Typography>
        //     )
        // }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
