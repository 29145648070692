import dashboard from './dashboard';
import rateCardConfig from './rateCardConfig';
import user from './user';
import resetApplication from './resetApplication';
import overview from './overview'
import classification from './classification'
import tickets from './tickets'
import genAI from "./genAI"
import fraitey from "./fraitey"
import accountPayableTrePO from './accountPayableTrePO'
import optima from "./optima"
import imageIntelligence from './imageIntelligence';
import contractManagement from './contractManagement';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [ dashboard,fraitey,imageIntelligence,  genAI, classification, contractManagement]
};

export default menuItems;
