// project imports
import config from "../config";

// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  isOpen: [], // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  token: "",
  snackbarStatus: {},
  userData: {},
  apiTrigger: false,
  invoiceStatus: "ALL",
  alertMessage: "",
  costAllocationValues: "GS1",
  ticketStatus: "TO_DO",
  autoAnalystData:[],
  demoAnalystData:[],
  threadId:"",
  aiAssistant:{},
  menuId:""
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const provenioaiReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.INVOICE_STATUS:
      return {
        ...state,
        invoiceStatus: action.invoiceStatus,
      };

    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.SNACKBAR_STATUS:
      return {
        ...state,
        snackbarStatus: action.snackbarStatus,
      };
    case actionTypes.TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isOpen: [],
        fontFamily: config.fontFamily,
        borderRadius: config.borderRadius,
        opened: false,
        token: "",
        snackbarStatus: {},
        invoiceStatus: "ALL",
        alertMessage: "",
        autoAnalystData:[],
        demoAnalystData:[],
        threadId:"",
        aiAssistant:{}
      };
    case actionTypes.USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    case actionTypes.API_TRIGGER:
      return {
        ...state,
        apiTrigger: action.apiTrigger,
      };
    case actionTypes.ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.alertMessage,
      };
    case actionTypes.COSTALLOCATION_VALUES:
      return {
        ...state,
        costAllocationValues: action.costAllocationValues,
      };
      case actionTypes.TICKET_STATUS:
        return {
          ...state,
          ticketStatus: action.ticketStatus,
        };
        case actionTypes.AUTO_ANALYST_DATA:
      return {
        ...state,
        autoAnalystData: [...state.autoAnalystData, ...action.autoAnalystData],
      };
      case actionTypes.DEMO_ANALYST_DATA:
      return {
        ...state,
        demoAnalystData: [...state.demoAnalystData, ...action.demoAnalystData],
      };
      case actionTypes.THREAD_ID:
        return {
          ...state,
          threadId: action.threadId,
        };
        case actionTypes.AI_ASSISTANT:
          return {
            ...state,
            aiAssistant: action.aiAssistant,
          };
          case actionTypes.MENU_ID:
            return {
              ...state,
              menuId: action.menuId,
            };
    default:
      return state;
  }
};

export default provenioaiReducer;
