import React, { useEffect, useMemo, useState} from "react";

import { useRoutes } from "react-router-dom";
import Cookies from "js-cookie";

// routes
import MainLayout from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import DashboardRoutes from "./Dashboard";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

  return useRoutes(
    Cookies.get("provenioToken") ? [ MainLayout] : [AuthenticationRoutes]
  );
}
